@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
/* ho */

/* ho */
html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  color: #ffd700;
}

body {
  background: #313131;
}

input[name="password"]:focus {
  box-shadow: none !important;
}

#banner_image {
  height: 25rem;
  object-fit: fill;
}

@media (max-width: 768px) {
  #banner_image {
    height: 12rem;
    object-fit: fill;
  }
}

/* football hover */
.football {
  transition: 0.7s ease;
}

.football:hover {
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
  transition: 0.7s ease;
}

.full-background {
  /* Center the content */
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* Take full size */
  height: 100vh;
  width: 100%;

  /* Background */
  background: url("https://img.freepik.com/premium-photo/3d-rendering-online-gambling_99433-4597.jpg?w=2000")
    center;
}

/* card slider start */
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: rgb(11, 11, 11);
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: auto;
}

.swiper-slide {
  text-align: center;
  font-size: 15.5px;
  padding-top: 3px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* card slider end */

/* card image hover */
.group {
  position: relative;
}

.image {
  opacity: 1;
  background-color: white;
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  opacity: 0;
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 42%;
  transform: translate(-40%, -40%);
  -ms-transform: translate(-30%, -30%);
}

.group:hover .image {
  opacity: 0.3;
}

.group:hover .middle {
  opacity: 1;
}

.text {
  background-color: transparent;
  color: rgb(223, 214, 214);
  font-size: 10px;
  font-weight: bolder;
  /* padding: 8px 0px; */
  /* padding: 16px 32px; */
  border-radius: 10%;
  transition: 0.5s;
  cursor: pointer;
  width: 80px;
  border: 3px solid goldenrod;
}

.text:hover {
  /* background: rgb(240, 232, 181); */
  color: black;
  font-weight: bolder;
  /* font-weight: 900; */
  background-color: white;
}

.image {
  background-color: black;
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: end;
}

.group:hover .image {
  opacity: 0.6;
}

.group:hover .middle {
  opacity: 1;
}

.group img {
  transition: 0.5s;
}

.group img:hover {
  opacity: 0.8;
}

.text {
  background-color: transparent;
  color: rgb(0, 0, 0);
  font-size: 16px;
  font-weight: bolder;
  /* padding: 16px 32px; */
  /* margin-right: -5px; */
  transition: 0.5s;
  border-radius: 10%;
  cursor: pointer;
  width: 130px;
  height: 50px;
  padding: auto;
}

.text:hover {
  background: rgb(240, 232, 181);
  color: black;
  font-weight: bold;
}

/* scroll bar start */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(9, 52, 131);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(2, 51, 107);
}

/* scroll bar end */

/* search btn */
.search-box {
  width: fit-content;
  height: fit-content;
  position: relative;
}

.input-search {
  height: 50px;
  width: 50px;
  border-style: none;
  padding: 10px;
  font-size: 18px;
  letter-spacing: 2px;
  outline: none;
  border-radius: 25px;
  transition: all 0.5s ease-in-out;
  background-color: transparent;
  padding-right: 40px;
  color: white;
}

.input-search::placeholder {
  color: white;
  font-size: 15px;
  letter-spacing: 2px;
  font-weight: 100;
}

.btn-search {
  width: 50px;
  height: 50px;
  border-style: none;
  font-size: 20px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  color: white;
  background-color: transparent;
  pointer-events: painted;
}

.btn-search:focus ~ .input-search {
  width: 300px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}

.input-search:focus {
  width: 300px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 1px solid goldenrod;
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}

.countBtn {
  background-color: white;
  color: black;
  cursor: pointer;
  transition: 0.3s;
}

.countBtn:hover {
  background-color: rgb(212, 186, 121);
  color: rgb(9, 9, 9);
}

.activeCountBtn {
  background-color: rgb(212, 186, 121);
  color: rgb(9, 9, 9);
}

.categoryBtn {
  background-color: #313131;
  transition: 0.3s;
}

.categoryBtn:hover {
  background-color: rgb(171, 156, 115);
  color: #0d0d0d;
}

/* text animation infinte */
.text-warper {
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
}

.text-warper span {
  white-space: nowrap;
  animation: move-rtl 20000ms linear infinite;
}

@keyframes move-rtl {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.profileBtn {
  padding-left: 15px !important;
}

.profileBtn:hover {
  border-radius: 5px;
  background-color: rgb(179, 22, 61);
  color: white;
}

.cash {
  opacity: 0.8;
  cursor: pointer;
  transition: 0.5s;
}

.cash:hover {
  opacity: 1.1;
  transform: scale(1.1);
}

.popup .overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9998;
  display: none;
}

.popup .content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  background: rgb(42, 41, 41);
  /* width: 200px; */
  height: auto;
  z-index: 9998;
  padding: 20px;
  box-sizing: border-box;
  color: white;
}

.popup .close-btn {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 30px;
  height: 30px;
  background: #222;
  color: #fff;
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
}

.popup.active .overlay {
  display: block;
}

.popup.active .content {
  transition: all 300ms ease-in-out;
  transform: translate(-50%, -50%) scale(1);
}

.countDigits {
  transition: 0.3s;
}

.countDigits:hover {
  border: 1px solid goldenrod;
}

.payAccount {
  transition: 0.3s;
  background-color: white;
}

.payAccount:hover {
  background-color: white;
}

.payAccountActive {
  background-color: white;
}

.gamehover {
  opacity: 1;
  transition: 0.5s;
}

.gamehover:hover {
  opacity: 0.3;
}

.bs-no-white-space {
  white-space: nowrap;
}

.all-games-name {
  color: white;
  text-align: center;
  font-size: 15.5px;
  padding-top: 3px;
  width: 200px;
}

.game-provider-active {
  background-color: rgb(171, 156, 115);
  color: #0d0d0d;
}

.blogImage {
  opacity: 1;
  transition: 0.5s;
  cursor: pointer;
}

.blogImage:hover {
  opacity: 0.5;
}

/* blog */
#item-blog p:has(iframe) {
  width: 100% !important;
  height: 100% !important;
}

#item-blog iframe {
  width: 100% !important;
  height: 100% !important;
}

.cursor-pointer {
  cursor: pointer;
}

.animate_lottery_5s {
  --animate-duration: 5s;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.bg_goldenrod {
  background-color: rgb(0, 0, 0);
}

.border-goldenrod {
  border-color: goldenrod !important;
}

.swiper-button-prev {
  color: gold !important;
  width: 5px !important;
  height: 50px !important;
}

.swiper-button-next {
  color: gold !important;
  width: 5px !important;
  height: 50px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-green {
  border-color: #14fc65 !important;
}

.border-orange {
  border-color: #fd6f09 !important;
}

.border-red {
  border-color: #ff1616 !important;
}

.border-gold {
  border-color: goldenrod !important;
}

.bs-flex {
  flex: 1;
}

.fixed-at-side {
  position: fixed;
  top: 13%;
  transform: translate(-65%, -55%);
  z-index: 999;
  left: 0;
  cursor: pointer;
  transition: all 0.6s ease-in-out;
}
.fixed-at-side:hover {
  transform: translate(-7%, -55%);
}

.text-goldenrod {
  color: white;
}

.back-btn {
  position: absolute;
  right: 55px;
  top: 20px;
  width: 30px;
  height: 30px;
  background: #222;
  color: #fff;
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
}

.banner {
  display: block;
}

.game-section {
  width: 200px;
}

.game-section-image {
  height: 160px;
}

.cashin-frame {
  background-color: #9cabb9;
  border: 1px solid rgb(1, 255, 234);
}

.betSection {
  width: 300px;
}

.font-responsive {
  font-size: 14px;
}

.swiper-pagination-bullet {
  background-color: #fff !important;
}

.swiper-pagination-bullet-active {
  background-color: rgb(250, 204, 21) !important;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #020c8b;
  border-radius: 10rem;
}

body {
  height: 100vh;
  background-color: rgb(1, 16, 34);
}

.frame-container {
  border: 4px solid #07056d88;
  border-radius: 15px;
}

.bet-info {
  width: 300px;
}

.diagram {
  width: 380px;
}

.bet-history {
  width: 350px;
}

.bet-history-three {
  width: 400px;
}

.bet-action {
  width: 400px;
}

.three-box {
  width: 800px;
}

@media screen and (max-width: 640px) {
  .swiper-container {
    width: 640px;
  }

  .game-section {
    width: 110px;
  }

  .game-section-image {
    height: 120px;
  }

  .all-games-name {
    color: white;
    text-align: center;
    font-size: 10px;
    padding-top: 3px;
    width: 100px;
  }

  .section-games-name {
    color: white;
    text-align: center;
    font-size: 10px;
    padding-top: 3px;
    width: 100px;
  }

  .text {
    width: 100px;
  }

  .frame-container {
    border: none;
    border-radius: 0px;
  }

  .cashin-frame {
    background-color: transparent;
    border: none;
  }

  .bet-info {
    width: 100%;
  }

  .diagram {
    width: 100%;
  }

  .bet-history {
    width: 100%;
  }

  .bet-history-three {
    width: 100%;
  }

  .bet-action {
    width: 100%;
  }
}

@media screen and (max-width: 440px) {
  .swiper-container {
    width: 440px;
  }

  .text {
    width: 80px;
  }

  .input-style {
    width: 35px;
  }
}

@media screen and (max-width: 580px) {
  .swiper-container {
    width: 580px;
  }
}

@media screen and (max-width: 868px) {
  .swiper-container {
    width: 768px;
  }
}

.mobile-side-bar > .active {
  background-color: #313131 !important;
  color: goldenrod !important;
}

.btn-light-gold {
  background-color: #313131 !important;
}

.game-preloader {
  position: fixed;
  padding: 0;
  margin: 0;
  z-index: 1000;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background: #0d0d0d;
}

.game-frame {
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.theme-color {
  background-color: #121313;
}

.text-color-main {
  color: #e2a101;
}

.main-border-color {
  border-color: #e2a101;
  border-width: 4px;
}

.main-bg {
  background-color: transparent;
  color: #e2a101;
}

.main-bg:hover {
  color: rgb(255, 230, 0);
}

.navbar-color {
  background-color: #090909;
}

.active-bg-color {
  background-color: #471712;
  color: rgb(255, 230, 0);
}
.active-bg-color:hover {
  color: red;
}

.cat_img {
  width: 93%;
}
.cat_img:hover {
  transform: scale(1.05);
}
.mainroute {
  margin-top: 3.3rem;
}

@media (max-width: 762px) {
  .cat_img {
    width: 100%;
  }
  .fixed-at-side {
    position: fixed;
    top: 94%;
    transform: translate(-90%, -50%);
    z-index: 999;
    border-radius: 100px;
    left: 96%;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
  }
  .fixed-at-side:hover {
    transform: translate(-90%, -50%);
  }
  .mainroute {
    margin-top: 6.5rem;
  }
}

.pulse {
  background-color: rgba(65, 63, 63, 0.911);
  border-radius: 50%;
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    background-color: rgba(54, 54, 54, 0.973);
  }

  70% {
    transform: scale(1);
    background-color: #3a3939f8;
  }

  100% {
    transform: scale(0.95);
    background-color: rgb(58, 57, 57);
  }
}

.show_bg {
  position: relative;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 2, 7, 0.808),
      rgba(17, 17, 17, 0.829)
    ),
    url("https://indiegamerchick.files.wordpress.com/2021/09/e-obayovuaqoepq.jpg");
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
}

.btn_div_no_img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
}

.opac {
  animation: opacity 2s linear;
}

@keyframes opacity {
  0% {
    opacity: 0;
  }

  70% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.search_form:focus {
  box-shadow: none !important;
}

.livechat {
  position: fixed;
  bottom: 0.5rem;
  right: 1rem;
  z-index: 100000;
  cursor: pointer;
}

.lciframe {
  z-index: 10000;
  position: fixed;
  bottom: 6.5rem;
  right: 1rem;
}

.livechatiframe {
  width: 100%;
  height: 500px;
  border: 3px solid black;
}

@media (max-width: 600px) {
  .lciframe {
    width: 93%;
  }
  .livechatiframe {
    width: 100%;
    height: 85vh;
  }
}

.livechatiframe::-webkit-scrollbar {
  width: 0px;
}

.point_div {
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
}

.point_boder {
  border: 1px solid rgba(255, 255, 255, 0.795);
  border-left: 0px;
  border-radius: 10rem;
  padding-left: 1.5rem;
  padding-right: 0.6rem;
  font-size: 0.85rem;
  font-weight: bold;
  color: white;
}

.point_boder:hover {
  background-color: #f5c109;
  color: black;
}

.point_image {
  position: relative;
  left: 1.4rem;
}

div.gallery {
  border: 1px solid #fce302;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

div.gallery:hover {
  border: 1px solid #777;
}

div.gallery img {
  width: 100%;
  height: 15rem;
  padding: 0.5rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

div.desc {
  padding: 15px;
  text-align: center;
}

.responsive {
  padding: 16px;
  float: left;
  width: 24.99999%;
}

@media only screen and (max-width: 700px) {
  .responsive {
    width: 49.99999%;
    margin: 6px 0;
  }
}

@media only screen and (max-width: 500px) {
  .responsive {
    width: 100%;
  }
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

/* CSS */
.button-85 {
  /* padding: 0.6em 2em; */
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-85:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #00111d;
  left: 0;
  top: 0;
  border-radius: 10px;
}

/* CSS */
.button-64 {
  align-items: center;
  background-image: linear-gradient(144deg,#033450, #062e63 50%,#004aeb);
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  font-family: Phantomsans, sans-serif;
  font-size: 12px;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 90px;
  padding: 10px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
}

.button-64:active,
.button-64:hover {
  outline: 0;
}

.button-64 span {
  background-color: rgb(5, 6, 45);
  padding: 16px 24px;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  transition: 300ms;
}

.button-64:hover span {
  background: none;
}

@media (min-width: 768px) {
  .button-64 {
    font-size: 12px;
    min-width: 90px;
  }
}